<template>
  <div class="box">
    <GlobalInfoBar title="追差分类管理" content="添加、编辑追差分类和追差选项" />
    <!-- 数据表格区 -->
    <div class="case">
      <div class="case-title">
        <img class="case-title_icon" src="@/assets/images/icon_ss@2x.png" alt />
        <div class="case-title_max">追差分类管理</div>
      </div>
      <div class="add-btn">
        <el-button type="primary" size="mini" icon="el-icon-plus" @click="setCatalog('add')">添加分类</el-button>
      </div>
      <div style="padding: 0 20px">
        <GlobalTable :maxHeight="800" class="sample-image" ref="GlobalTable" v-loading="tableLoading"
          :columns="tableColumns" :data="tableData" :isPagination="false">
          <el-table-column label="类型" slot="type" align="center">
            <template slot-scope="{ row }">
              <div :class="row.type==='01'?'blue-color':'red-color'">{{ row.type==='01'?'单选':'多选' }}</div>
            </template>
          </el-table-column>
          <el-table-column width="180" label="排序" slot="sort" align="center">
            <template slot-scope="{ row }">
              <el-input-number v-model="row.sort" @change="handleChange(row)" size="mini" :min="0"
                label="排序"></el-input-number>
            </template>
          </el-table-column>
          <el-table-column label="添加人" slot="createUser" align="center">
            <template slot-scope="{ row }">
              <div>{{ row.createUserName }}-{{ row.createUserAcc }}</div>
            </template>
          </el-table-column>
          <el-table-column label="更新操作人" slot="updateUser" align="center">
            <template slot-scope="{ row }">
              <div>{{ row.updateUserName }}-{{ row.updateUserAcc }}</div>
            </template>
          </el-table-column>
          <el-table-column label="操作" slot="operation" align="center">
            <template slot-scope="{ row }">
              <el-button type="text" @click="setCatalog(type, row)">编辑分类</el-button>
            </template>
          </el-table-column>
        </GlobalTable>
      </div>
    </div>
    <!-- 添加/编辑追差分类弹框 -->
    <DialogAction v-bind="optionDialogProps" @close="optionDialogProps.show = false" @config="optionConfig">
      <template v-slot:default>
        <div class="option_form">
          <div class="option_normal">
            <span class="title-font">分类名称:</span>
            <el-input v-model="optionsData.name" placeholder="请输入分类名称，最多5个字" clearable maxlength="5" />
          </div>
          <!-- *******类型****** -->
          <div class="option_normal">
            <div class="title-font">类型:</div>
            <div>
              <el-radio v-model="optionsData.type" label="01">单选</el-radio>
              <el-radio v-model="optionsData.type" label="02">多选</el-radio>
            </div>
          </div>
          <!-- ********** -->
          <div class="title-font">追差选项</div>
          <div class="option_abnormal" v-for="(item, index) in optionsData.itemList" :key="index">
            <span>{{ index + 1 }}.</span>
            <el-input style="margin-right: 10px;" v-model="item.name" maxlength="30" placeholder="请输入追差选项，最多30个字" />
            <el-button type="danger" size="mini" style="margin-left: 10px;"
              @click="optionsData.itemList.splice(index, 1)">
              刪除选项
            </el-button>
          </div>
          <el-button @click="addOption" size="small " style="margin-top: 10px;" icon="el-icon-plus" type="primary">添加选项
          </el-button>
        </div>
      </template>
    </DialogAction>
  </div>
</template>
<script>
import _api from "@/utils/request";
import GlobalInfoBar from "@/components/global/components/GlobalInfoBar.vue";
import DialogAction from "@/components/common/DialogAction.vue";
export default {
  name: "catchUpCategory",
  components: { GlobalInfoBar, DialogAction },
  data() {
    return {
      // 添加/编辑弹框数据
      optionDialogProps: {
        show: false,
        currentData: {},
        title: "",//新建追差分类
        width: "40%",
      },
      // 添加/编辑数据
      optionsData: {
        id: 0,
        name: undefined,//分类名称
        type: '',//类型（01：单选，02：多选）
        // 追差选项
        itemList: [],
      },
      tableColumns: [
        { label: "分类ID", prop: "id" },
        { label: "追差分类", prop: "catalogName" },
        { slotName: "type" },//类型
        { label: "发起追差单量", prop: "aprOrderNum" },
        { label: "追差完成单量", prop: "aprFinishOrderNum" },
        { slotName: "sort" },//排序
        { label: "添加时间", prop: "createTime" },
        { slotName: "createUser" },//添加人
        { label: "最新更新时间", prop: "updateTime" },
        { slotName: "updateUser" },//更新操作人
        { slotName: "operation" },
      ],
      tableData: [],
      tableLoading: false,
    }
  },
  created() {
    // 列表数据
    this.catalogList()
  },
  methods: {
    // 添加/编辑
    setCatalog(type, row) {
      this.optionsData={
        id: 0,
        name: undefined,//分类名称
        type: '',//类型（01：单选，02：多选）
        // 追差选项
        itemList: [],
      }, //置空
      this.optionDialogProps.show = true
      if (type === 'add') {//添加
        this.optionDialogProps.title = '新建追差分类'
      } else {//编辑
        this.catalogInfo(row.id)
        this.optionDialogProps.title = '编辑追差分类'
      }
    },
    // 追差分类详情
    catalogInfo(id){
      const params = {
        id:id,
      };
      _api.catalogInfo(params).then((res) => {
        this.optionsData = res.data ? res.data : [];
      });
    },
    // 添加/编辑确定
    optionConfig() {
      const { itemList, name } = this.optionsData;
      const isNull = itemList.every(v => v.name);
      if (!isNull) {
        return this.$message.error("分类名称未填写")
      }
      const dataBody = {
        itemList: itemList,
        id:this.optionsData.id?this.optionsData.id:'',
        name: this.optionsData.name,
        type: this.optionsData.type
      }
      _api.catalogSave(dataBody).then(r => {
        this.optionDialogProps.show = false;
        // 列表数据
        this.catalogList()
        this.$message.success("操作成功");
      })
    },
    // 追差分类列表
    catalogList() {
      _api.catalogList().then((res) => {
        this.tableData = res.data ? res.data : [];
      });
    },
    // 添加选项
    addOption() {
      this.optionsData.itemList.push({
        name: ""
      })
    },
    // 排序
    handleChange(row) {
      const params = {
        id: row.id,
        sort: row.sort,
      };
      _api.catalogUpdateSort(params).then((res) => {
          if (res.code === 1) {
            this.catalogList();
            this.$message.success(res.msg);
          }
        });
    },
  }
}
</script>
<style lang="scss" scoped>
.blue-color{
  color: #0981FF;
}
.red-color{
  color: #F59A23;
}
.title-font {
  font-size: 16px;
  font-weight: 600;
  margin-right: 8px;
  width: 70px;
  // text-align: right;
}

.option_form {
  max-height: 60vh;
  overflow: auto;

  .option_normal {
    margin-top: 6px;
    margin-bottom: 16px;
    display: flex;
    align-items: center;
  }

  /deep/ .el-input {
    width: 60%;
  }

  >.option_abnormal {
    margin-top: 10px;
    display: flex;
    align-items: center;

    >span {
      margin-right: 6px;
    }

    /deep/ .el-upload {
      width: 60px;
      height: 60px;
    }
  }
}

.add-btn {
  padding: 0 20px;
  margin-bottom: 10px;
}

.case {
  background: rgba(255, 255, 255, 1);
  border-radius: 20px;
  width: 100%;
  padding: 14px 0;

  .class_type {
    padding: 20px;

    .tip {
      font-size: 14px;
      color: #666666;
    }

    /deep/.el-radio-group {
      .el-radio-button {
        margin-right: 20px;

        .el-radio-button__inner {
          border-radius: 4px;
          border: 1px solid #0981ff;
        }
      }
    }
  }

  .case-title {
    padding: 0 20px;
    display: flex;
    align-items: center;

    .case-title_icon {
      width: 20px;
      height: 20px;
      margin-right: 3px;
    }

    .case-title_max {
      // width: 150px;
      // height: 17px;
      font-size: 18px;
      font-family: FZLanTingHei-B-GBK;
      font-weight: 400;
      color: #333333;
      line-height: 41px;
      margin-right: 10px;
    }

    .case-title_min {
      margin-left: 5px;
      width: 500px;
      height: 13px;
      font-size: 12px;
      font-family: FZLanTingHei-M-GBK;
      font-weight: 400;
      color: #ff687b;
      line-height: 45px;
    }
  }

  .case-img {
    margin: 20px;
    height: 280px;
    background: #f5f6fa;
    border-radius: 10px;
    display: flex;
    align-items: center;

    .upload_Add {
      width: 200px;

      // height: 200px;
      /deep/.el-upload--picture-card {
        margin: 0 30px;
      }
    }

    .upload_Class {
      position: relative;
      width: 200px;

      // height: 200px;
      .img:hover .mark {
        position: absolute;
        top: 0;
        left: 0;
        width: 148px;
        height: 148px;
        background: rgba(0, 0, 0, 0.7);
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          cursor: pointer;
          width: 20px;
          height: 20px;
        }
      }

      .img {
        position: relative;
        padding: 0;
        width: 148px;
        height: 148px;
        border-radius: 12px;
        margin: 0 auto;

        img {
          width: 148px;
          height: 148px;
        }

        .mark {
          display: none;
        }
      }

      .sort {
        width: 130px;
        height: 30px;
        margin: 10px auto;
      }

      .imageName {
        width: 130px;
        height: 30px;
        margin: 10px auto;
      }
    }
  }

  .sample-image {
    /deep/.el-switch__label * {
      line-height: 1;
      font-size: 12px;
      display: inline-block;
    }

    /deep/.el-switch__label {
      position: absolute;
      display: none;
      color: #fff !important;
      font-size: 12px !important;
    }

    /*打开时文字位置设置*/
    /deep/.el-switch__label--right {
      z-index: 1;
      right: 4px;
    }

    /*关闭时文字位置设置*/
    /deep/.el-switch__label--left {
      z-index: 1;
      left: 4px;
    }

    /*显示文字*/
    /deep/.el-switch__label.is-active {
      display: block;
    }

    /*开关宽度*/
    /deep/.el-switch .el-switch__core,
    /deep/.el-switch .el-switch__label {
      width: 50px !important;
    }
  }
}
</style>
